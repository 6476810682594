/**
 *  JS: resources/views/assets/js/validation_landing.js
 *
 */
$(document).ready(function () {
    var $polizza = $('input[name="polizza"]');
    var $data = $('#bdate');
    var $piva = $('#piva');
    /*$polizza.mask("SS00000000", {
        'placeholder': 'AA99999999',
        'translation': {
            S: {pattern: /[A-Za-z]/},
            0: {pattern: /[0-9]/}
        },
        onKeyPress: function (value, event) {
            event.currentTarget.value = value.toUpperCase();
        }
    });*/
    $data.mask('ABrCBrDBBB', {
        'translation': {
            'A': {pattern: /[0-3]/},
            'B': {pattern: /[0-9]/},
            'C': {pattern: /[0-1]/},
            'D': {pattern: /[12]/},
            'r': {
                pattern: /[\/]/,
                fallback: '/'
            }
        },
        placeholder: 'GG/MM/AAAA',
        dataMask: true,
        watchInterval: 300,
        watchInputs: true,
        watchDataMask: true
    });
    $piva.mask("00000000000", {"placeholder": "99999999999"});
});