/**
 *  JS: resources/views/assets/js/baloon.js
 *
 */
$(document).ready(function() {
    if ($('main').hasClass('landing')) {
        $('#remind').tooltipster({
            animation: 'grow',
            delay: 400,
            minWidth: 300,
            maxWidth: 350,
            theme: 'tooltipster-shadow',
            contentCloning: true,
            //content: $('#remindme'),
            side: 'right',
            zIndex: 9999,
            interactive: true,
            functionReady: function() {
                /*
                // Serve solo in caso di header sticky
                var $baloon = $('.tooltipster-base');
                var current_top_pos = parseInt($baloon.css('top').replace('px',''));
                //console.log($(window).innerWidth());
                if ($(window).innerWidth() < 768) {
                    current_top_pos = current_top_pos - 65;
                    $baloon.css('top',current_top_pos.toString() + 'px');
                    //console.log('mobile: ' + current_top_pos.toString() + 'px');
                } else {
                    current_top_pos = current_top_pos - 105;
                    $baloon.css('top',current_top_pos.toString() + 'px');
                    //console.log('desktop: ' + current_top_pos.toString() + 'px');
                }
                */
            },
            trigger: 'click'
        });
    }
});