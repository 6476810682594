/**
 *  JS: resources/views/assets/js/formLand.js
 *
 */

$(document).ready(function () {
    function changeTypeGuest() {
        var typePerson = $('input[name="tipo"]:checked').val();
        if (typePerson == "P") {
            $('.partitaIva').hide();
            $('.dataNascita').show()
        } else {
            $('.partitaIva').show();
            $('.dataNascita').hide()
        }
    }
    changeTypeGuest();
    $('input[name="tipo"]').change(function(){ changeTypeGuest() })
    $('#procedi').click(function(){
        $('.landing_loading').fadeIn(400);
    });
});